@tailwind base;
@tailwind components;
@tailwind utilities;

// Force render (since class isn't present in the component)
@import "./components/modal.scss";

@layer base {
  body,
  html {
    @apply font-display text-base;
    height: 100%;
  }

  body {
    @apply bg-white;
  }

  a {
    &.disabled {
      @apply pointer-events-none;
    }
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  .text-content p {
    @apply mb-4 whitespace-pre-line;

    &:last-child {
      @apply mb-0;
    }
  }

  .text-content li {
    @apply text-2xl leading-[1.5] list-disc list-inside;
  }

  .h-wh {
    height: var(--wh);
  }

  #CookiebotWidget {
    @apply hidden;
  }
}

@layer components {
  // Components
  @import "./components/button.scss";
  @import "./components/image-container.scss";
  @import "./components/header.scss";
  @import "./components/loader.scss";
  @import "./components/hero-carousel.scss";
  @import "./components/swiper.scss";
  @import "./components/notificationbar.scss";

  // Modules
  @import "./modules/module-text.scss";
  @import "./modules/module-text-accordion.scss";
  @import "./modules/module-content-accordion.scss";
  @import "./modules/module-image-accordion.scss";
  @import "./modules/module-product-accordion.scss";
  @import "./modules/module-cta.scss";
  @import "./modules/module-text-image.scss";
  @import "./modules/module-timeline.scss";

  .container {
    @apply max-w-[1680px] px-4 lg:px-8 mx-auto;
  }

  .site-grid {
    @apply sm:grid grid-cols-12 gap-x-5 lg:gap-x-8;
  }

  .text-grayed {
    strong {
      color: black;
      font-weight: inherit;
    }
  }

  .heading {
    @apply text-2xl lg:text-4xl mb-10 font-display;
  }

  .list li {
    @apply pl-2;
  }

  .list-ordered li {
    @apply pl-2 list-decimal;
  }

  .options__item:hover .options__img {
    @apply p-2 border-black;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
