.module-text-image {
  @apply mt-40;
  
  &:nth-of-type(2n) {
    .module-text-image--text {
      @apply md:-order-1;
    }
  }

  & + .module-text-image {
    @apply mt-24;
  }
}