.hero_carousel__slide {
  --animation-delay-ms: 6000ms;
  @apply absolute inset-0 w-full h-full;
  transform: scale(1.2);

  &.is-active {
    animation: kenBurnsAnimation var(--animation-delay-ms) linear infinite;
  }
}

@keyframes kenBurnsAnimation {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
  }
}
