.ReactModal {
  &__Body--open {
    @apply overflow-hidden;
  }

  &__Overlay {
    @apply fixed z-modal inset-0 transition-all duration-500 ease-in-out;

    .is-sidebar & {
      @apply bg-black bg-opacity-0;

      &--after-open {
        @apply bg-opacity-25;
      }
    }

    .is-project & {
      @apply bg-modal bg-opacity-0;

      &--after-open {
        @apply bg-opacity-95;
      }
    }

    .is-project &,
    .is-sidebar & {
      &--before-close {
        @apply bg-opacity-0;
      }
    }
  }

  &__Content {
    @apply transition-all duration-500 ease-in-out bg-white absolute top-0 right-0 transform outline-none h-wh overflow-scroll;

    .from-bottom & {
      @apply bottom-0 translate-y-full;

      &--after-open {
        @apply translate-y-0;
      }

      &--before-close {
        @apply translate-y-full;
      }
    }

    .from-right & {
      @apply right-0 translate-x-full;

      &--after-open {
        @apply translate-x-0;
      }

      &--before-close {
        @apply translate-x-full;
      }
    }

    .is-sidebar &,
    .is-project & {
      @apply right-0;
    }

    .is-sidebar & {
      @apply w-[384px] max-w-full duration-500 flex flex-col;
    }

    .is-project & {
      @apply left-0 lg:left-[16%];
    }
  }
}
