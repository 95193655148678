.module-text-accordion {
  .accordion--content {
    ul {
      @apply list-disc space-y-2 mb-5 text-grayed ml-5;
    }
    
    li {
      @apply text-xl;
    }
  }
}