.btn {
  @apply border border-primary py-2 px-4 rounded-3xl inline-block text-lg transition-all duration-75 ease-in-out;
  
  &:hover {
    @apply bg-black text-white;
  }
  
  &.btn-primary {
    @apply bg-black text-white;
  
    &:hover {
      @apply bg-white text-black;
    }
  }

  &.btn-inverted {
    @apply bg-white text-black border-white;
    
    &:hover {
      @apply bg-transparent text-white;
    }
  }

  &.btn-disabled {
    @apply opacity-50 cursor-not-allowed;
  }
  
  &:disabled, &.disabled {
    @apply opacity-20;
  }
}
