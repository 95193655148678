/* next/image classes */
body,
header,
.logo {
  transition: all 1000ms ease-in-out;
}
.notificationbar {
  @apply h-0;
  transition: height 1000ms ease-in-out;
}

body.notificationbar-visible {
  @apply pt-14;

  header,
  .logo {
    @apply mt-14;
  }

  .notificationbar {
    @apply h-14;
  }
}
