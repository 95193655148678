.module-content-accordion {
  &--item {
    @apply lg:site-grid;
  }

  h4 {
    @apply text-3xl;
  }

  p {
    @apply text-lg;
  }

  .accordion--content {
    @apply lg:-translate-y-[49px];
  }
}