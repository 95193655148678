.module-text {
  @apply mt-32 md:mt-44 lg:mt-60;

  &.is-secondary {
    @apply mt-24 md:mt-36 lg:mt-48;
  }
}

.module-hero + .module-text, header + .module-text {
  @apply mt-0;

  .site-grid {
    @apply border-t-0 pt-14;
  }
}