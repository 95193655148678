.module-product-accordion {

  .border-t {
    @apply transition duration-500;
  }

  .is-active {
    @apply border-t-grayed;
  }

  h3 {
    @apply text-2xl;
  }
  
  p {
    @apply text-lg whitespace-pre-line mb-4 p-0;

    &:last-child {
      @apply mb-0;
    }
  }

  .accordion--content {
    @apply pb-0;

    div {
      @apply pb-10;
    }
  }
}