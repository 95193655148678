.image-container {
  width: 100%;
  
  &.is-scaling {
    overflow: hidden;
    
    > *{
      @apply transition-transform hover:scale-110;
      transition-duration: 3s;
      transition-timing-function: linear;
    }

  }

  > div {
    position: unset !important;
  }

  span,
  .image {
    position: relative !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    height: unset !important;

  }
}
