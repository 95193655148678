.loader {
  &-border {
    @apply bg-secondary w-24 h-24;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(#03111C,0.1) 33%, #03111C 100%);
    animation: spin .8s linear 0s infinite;

    &.is-sm {
      @apply w-12 h-12;
    }
  }

  &-core {
    @apply w-full h-full bg-white rounded-full;
  }
}
